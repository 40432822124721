
.App {  
  text-align: center;
  color: white;
  background-color: rgb(15 23 42);
  margin: 0px;
  width: 100%;
  font-family: Helvetica;
  min-height: 100vh;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}

#body {
  margin: 0px;
}

.menuTotal {
  height: 100%;
  width: 250px;
  position: fixed;
  overflow: none;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 0.5s ease;
  transform: translateX(-250px);
  background-color: #1e1e2e;
}

.headMenu {
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  text-align: center;
  letter-spacing: 3px;
}

.listMenu {
  display: block;
  width: 100%;
}

.listMenu button{
  padding-left: 50px;
  padding-bottom: 15px;
  padding-top: 15px;
  text-align: left;
  text-decoration: none;
  display: block;
  font-size: 15px;
  letter-spacing: 1px;
  position: relative;
  transition: 0.3s;
  overflow: hidden;
  text-transform: capitalize;
  color: #fff;
  background-color: transparent;
  border: none;
  width: 100%;
  cursor: pointer;
}

.listMenu:hover button{
  background-color: #46466d;
  color: #ffe600;
  letter-spacing: 5px;
}
.bodyTotal {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 20px;
}

.menuBar {
  visibility: hidden;
  display: none;
}

.labelMenu span {
  position: absolute;
  right: -40px;
  top: 30px;
  font-size: 25px;
  border-radius: 3px;
  color: #fff;
  padding: 3px 8px;
  cursor: pointer;
  background-color: #222;
}

#check:checked ~ .menuTotal {
  transform: translateX(0);
}

#check:checked ~ .bodyRoutes {
  filter: brightness(60%);
}

#check:checked ~ .menuTotal #bars {
  display: none;
}

.dark-select {
  position: relative;
  display: inline-block;
  width: 70px;
  font-size: 0.875rem;
  margin-top: 10px;
}

.dark-select__selected {
  display: flex;
  align-items: center;
  padding: 5px 12px;
  background-color: #222;
  color: #fff;
  border: 1px solid #555;
  border-radius: 4px;
  cursor: pointer;
}

.dark-select__selected img {
  width: 20px;
  height: auto;
  margin-right: 8px;
}

.dark-select__options {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 70px;
}

.dark-select__option {
  display: flex;
  align-items: center;
  padding: 5px 11px;
  background-color: #222;
  color: #fff;
  cursor: pointer;
}

.dark-select__option img {
  width: 20px;
  height: auto;
  margin-right: 8px;
}

.dark-select__option:hover {
  background-color: #444;
}

@media (min-width: 1550px) {
  .habilidades {
    /* margin-left: 8rem; */
  }

  .box {
    width: 60px;
    font-size: 12px;
  }

  .sectionHabilidades {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }

  .sectionHome {
    padding-top: 6rem;
    padding-left: 8rem;
    text-align: left;
    width: 920px;
  }

  .sectionAbout {
    padding-top: 3rem;
    padding-left: 8rem;
    text-align: left;
    width: 80%;
  }

  .parrafo {
    color: rgb(148 163 184);
    display: flex;
    width: 90%;
    font-size: 28px;
  }
  
  .description1 {
    color: rgb(148 163 184);
    display: flex;
    margin-bottom: 32px;
    width: 90%;
    font-size: 28px;
  }
  
  .description2 {
    color: rgb(148 163 184);
    display: flex;
    width: 90%;
    font-size: 28px;
  }

  .title {
    display: flex;
    font-size: 70px;
  }

  .titleHabilidades {
    display: flex;
    font-size: 40px;
  }

  .avatar {
    height: 300px; 
    padding-top: 4rem; 
    padding-left: 10rem;
  }

  .tecnologia {
    height: 300px;
    padding-top: 4rem;
  }

  .divRedesSociales {
    padding-top: 2rem;
    padding-left: 8rem;
  }

  .redesSociales {
    height: 45px;
    padding-right: 20px;
  }
}

@media (min-width: 1440px) and (max-width: 1550px) {
  .habilidades {
    /* margin-left: 8rem; */
  }

  .box {
    width: 60px;
    font-size: 12px;
  }

  .sectionHabilidades {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }

  .sectionHome {
    padding-top: 6rem;
    padding-left: 8rem;
    text-align: left;
    width: 920px;
  }

  .sectionAbout {
    padding-top: 3rem;
    padding-left: 8rem;
    text-align: left;
    width: 80%;
  }

  .parrafo {
    color: rgb(148 163 184);
    display: flex;
    font-size: 28px;
  }
  
  .description1 {
    color: rgb(148 163 184);
    display: flex;
    margin-bottom: 32px;
    width: 90%;
    font-size: 28px;
  }
  
  .description2 {
    color: rgb(148 163 184);
    display: flex;
    width: 90%;
    font-size: 28px;
  }

  .title {
    display: flex;
    font-size: 70px;
  }

  .titleHabilidades {
    display: flex;
    font-size: 40px;
  }

  .avatar {
    height: 300px; 
    padding-top: 4rem; 
    padding-left: 6rem;
  }

  .tecnologia {
    height: 300px;
    padding-top: 4rem;
  }

  .divRedesSociales {
    padding-top: 2rem;
    padding-left: 8rem;
  }

  .redesSociales {
    height: 45px;
    padding-right: 20px;
  }
}

@media (min-width: 1250px) and (max-width: 1440px) {
  .habilidades {
    /* margin-left: 8rem; */
  }

  .box {
    width: 60px;
    font-size: 12px;
  }

  .sectionHabilidades {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
  }

  .sectionHome {
    padding-top: 6rem;
    padding-left: 8rem;
    text-align: left;
    width: 800px;
  }

  .sectionAbout {
    padding-top: 3rem;
    padding-left: 8rem;
    text-align: left;
    width: 80%;
  }

  .parrafo {
    color: rgb(148 163 184);
    display: flex;
    font-size: 28px;
  }
  
  .description1 {
    color: rgb(148 163 184);
    display: flex;
    margin-bottom: 32px;
    width: 90%;
    font-size: 28px;
  }
  
  .description2 {
    color: rgb(148 163 184);
    display: flex;
    width: 90%;
    font-size: 28px;
  }

  .title {
    display: flex;
    font-size: 60px;
  }

  .titleHabilidades {
    display: flex;
    font-size: 30px;
  }

  .avatar {
    height: 300px; 
    padding-top: 4rem; 
    padding-left: 2rem;
  }

  .tecnologia {
    height: 300px;
    padding-top: 4rem;
  }

  .divRedesSociales {
    padding-top: 2rem;
    padding-left: 8rem;
  }

  .redesSociales {
    height: 45px;
    padding-right: 20px;
  }
}

@media (min-width: 1050px) and (max-width: 1250px) {
  .habilidades {
    /* margin-left: 5rem; */
  }

  .box {
    width: 60px;
    font-size: 12px;
  }

  .sectionHabilidades {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
  }

  .sectionHome {
    padding-top: 6rem;
    padding-left: 5rem;
    text-align: left;
    width: 660px;
  }

  .sectionAbout {
    padding-top: 3rem;
    padding-left: 5rem;
    text-align: left;
    width: 80%;
  }

  .parrafo {
    color: rgb(148 163 184);
    display: flex;
    font-size: 28px;
  }
  
  .description1 {
    color: rgb(148 163 184);
    display: flex;
    margin-bottom: 32px;
    width: 90%;
    font-size: 28px;
  }
  
  .description2 {
    color: rgb(148 163 184);
    display: flex;
    width: 90%;
    font-size: 28px;
  }

  .title {
    display: flex;
    font-size: 50px;
  }

  .titleHabilidades {
    display: flex;
    font-size: 24px;
  }

  .avatar {
    height: 200px; 
    padding-top: 4rem; 
    padding-left: 6rem;
  }

  .tecnologia {
    height: 200px;
    padding-top: 4rem;
  }

  .divRedesSociales {
    padding-top: 2rem;
    padding-left: 5rem;
  }

  .redesSociales {
    height: 35px;
    padding-right: 20px;
  }
}

@media (min-width: 850px) and (max-width: 1050px) {
  .habilidades {
    /* margin-left: 5rem; */
  }

  .box {
    width: 60px;
    font-size: 12px;
  }

  .sectionHabilidades {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }

  .sectionHome {
    padding-top: 6rem;
    padding-left: 5rem;
    text-align: left;
    width: 500px;
  }

  .sectionAbout {
    padding-top: 3rem;
    padding-left: 5rem;
    text-align: left;
    width: 80%;
  }

  .parrafo {
    color: rgb(148 163 184);
    display: flex;
    font-size: 28px;
  }
  
  .description1 {
    color: rgb(148 163 184);
    display: flex;
    margin-bottom: 32px;
    width: 90%;
    font-size: 28px;
  }
  
  .description2 {
    color: rgb(148 163 184);
    display: flex;
    width: 90%;
    font-size: 28px;
  }

  .title {
    display: flex;
    font-size: 50px;
  }

  .titleHabilidades {
    display: flex;
    font-size: 24px;
  }

  .avatar {
    height: 200px; 
    padding-top: 4rem; 
    padding-left: 4.5rem;
  }

  .tecnologia {
    height: 200px;
    padding-top: 4rem;
  }

  .divRedesSociales {
    padding-top: 2rem;
    padding-left: 5rem;
  }

  .redesSociales {
    height: 35px;
    padding: 10px;
  }
}

@media (min-width: 600px) and (max-width: 850px) {
  .sectionHabilidades {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (min-width: 400px) and (max-width: 600px) {
  .sectionHabilidades {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 300px) and (max-width: 400px) {
  .sectionHabilidades {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 260px) and (max-width: 300px) {
  .sectionHabilidades {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 260px) and (max-width: 850px) {
  .habilidades {
    margin-top: 2rem;
    /* margin-left: 3rem; */
    font-size: 7px;
  }

  .box {
    width: 40px;
    font-size: 10px;
  }

  .sectionHome {
    padding-top: 2rem;
    padding-left: 3rem;
    text-align: left;
  }

  .sectionAbout {
    padding-top: 2rem;
    padding-left: 3rem;
    text-align: left;
    width: 80%;
  }

  .title {
    display: flex;
    font-size: 22px;
  }

  .titleHabilidades {
    display: flex;
    font-size: 18px;
  }

  .parrafo {
    color: rgb(148 163 184);
    display: flex;
    font-size: 14px;
  }
  
  .description1 {
    color: rgb(148 163 184);
    display: flex;
    width: 90%;
    margin-bottom: 32px;
    font-size: 14px;
  }
  
  .description2 {
    color: rgb(148 163 184);
    display: flex;
    width: 90%;
    font-size: 14px;
  }

  .fotoTitle {
    flex-direction: column-reverse;
  }
  
  .avatar {
    height: 150px; 
    padding-top: 3.5rem;
  }

  .tecnologia {
    height: 150px;
    padding-top: 1rem;
  }

  .divRedesSociales {
    padding-top: 2rem;
    padding-left: 3rem;
  }

  .redesSociales {
    height: 25px;
    padding-right: 20px;
  }
}

@media (max-width: 260px) {
  .habilidades {
    margin-top: 2rem;
    margin-left: 3rem;
    font-size: 7px;
  }

  .sectionHabilidades {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .box {
    width: 40px;
    font-size: 10px;
  }

  .sectionHome {
    padding-top: 2rem;
    padding-left: 3rem;
    text-align: left;
  }

  .sectionAbout {
    padding-top: 2rem;
    padding-left: 3rem;
    text-align: left;
    width: 80%;
  }

  .title {
    display: flex;
    font-size: 14px;
  }

  .titleHabilidades {
    display: flex;
    font-size: 12px;
  }

  .parrafo {
    color: rgb(148 163 184);
    display: flex;
    font-size: 12px;
  }
  
  .description1 {
    color: rgb(148 163 184);
    display: flex;
    width: 90%;
    margin-bottom: 32px;
    font-size: 12px;
  }
  
  .description2 {
    color: rgb(148 163 184);
    display: flex;
    width: 90%;
    font-size: 12px;
  }

  .fotoTitle {
    flex-direction: column-reverse;
  }
  
  .avatar {
    height: 50px; 
    padding-top: 3.5rem;
  }

  .tecnologia {
    height: 50px;
    padding-top: 1rem;
  }

  .divRedesSociales {
    padding-top: 2rem;
    padding-left: 3rem;
  }

  .redesSociales {
    height: 25px;
    padding-right: 20px;
  }
}

.redesSociales:hover {
  scale: 1.2;
  transition: 0.3s;
  cursor: pointer;
}

.aMemo {
  border: 3px solid rgb(255, 255, 255);
  padding: 10px;
}

@media (min-width: 1700px) {
  .aMemo {
    width: 400px;
    margin: 50px;
  }
}

@media (min-width: 850px) and (max-width: 1700px) {
  .aMemo {
    width: 300px;
    margin: 30px;
  }
}

@media (max-width: 850px) {
  .aMemo {
    width: 150px;
    margin: 30px;
  }
}

@media (min-width: 1300px) {
  .divProjects {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 850px) and (max-width: 1300px) {
  .aMemo {
    width: 300px;
    margin: 30px;
  }
}

@media (min-width: 530px) and (max-width: 1300px) {
  .divProjects {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
  }
}

.fotoMemo:hover {
  scale: 1.05;
}