.titleHabilidades {
  margin: 20px;
}


@media (min-width: 1350px) {
    .titleAbout {
        display: flex;
        padding-left: 390px;
        font-size: 70px;
    }
  }
  
  .box {
    padding: 5px;
    margin-right: 10px;
    margin-left: 20px;
    margin-bottom: 20px;
    height: 25px;
    display: grid;
    place-content: center;
    color: #59ddca;
    text-shadow: 0 1px 0 #000;
    --border-angle: 0turn;
    /* --main-bg: conic-gradient(from var(--border-angle), rgb(15 23 42), rgb(15 23 42) 5%, rgb(15 23 42) 60%, rgb(15 23 42) 95%); */
    --main-bg: conic-gradient(from var(--border-angle), #122b39, #122b39 5%, #122b39 60%, #122b39 95%);
    border: solid 2px transparent;
    border-radius: 15px;
    background-color: #122b39;
    /* --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #08f, #f03 99%, transparent); */
    --gradient-border: conic-gradient(from var(--border-angle), transparent 60%, #59ddca, rgb(15 23 42) 99%, transparent);
    background: var(--main-bg) padding-box, var(--gradient-border) border-box, var(--main-bg) border-box;
    background-position: center center;
    animation: bg-spin 3s linear infinite;
 }
  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
   }
 }
  @property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
 }

 .habilidades {
  border-radius: 10px;
  border: 2px solid #152447;
  background-color: #0b132796;
  width: fit-content;
  padding-right: 10px;
 }
  