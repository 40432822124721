.timeline {
    position: relative;
    margin: 40px auto;
  }
  
  .timeline-item {
    position: relative;
    padding: 20px;
    margin: 30px 0;
  }


  .timeline-event {
    position: absolute;
    width: 10px; /* Ancho del punto */
    height: 10px; /* Altura del punto */
    background-color: #007bff; /* Color del punto */
    border-radius: 50%; /* Para hacer el punto redondo */
    transform: translateY(-50%);
  }

  @media (min-width: 1250px) {
    .timeline-event {
      top: 1%;
      left: 8rem;
    }
    .timeline-item:before {
      content: '';
      position: absolute;
      top: 10%;
      left: 8.3rem;
      width: 2px;
      height: 100%;
      background: #333;
      transform: translateX(-50%);
    }

    .timeline-item-date {
      padding-left: 10rem;
      font-size: 14px;
    }
    
    .timeline-item-title {
      padding-left: 10rem;
      font-size: 18px;
    }
    
    .timeline-item-description {
      padding-left: 10rem;
      font-size: 14px;
      width: 80%;
    }
  }
  
  @media (min-width: 850px) and (max-width: 1250px) {
    .timeline-event {
      top: 1%;
      left: 5rem;
    }

    .timeline-item-date {
      padding-left: 6rem;
      font-size: 12px;
    }
    
    .timeline-item-title {
      padding-left: 6rem;
      font-size: 16px;
    }
    
    .timeline-item-description {
      padding-left: 6rem;
      font-size: 12px;
      width: 80%;
    }

    .timeline-item:before {
      content: '';
      position: absolute;
      top: 10%;
      left: 5.3rem;
      width: 2px;
      height: 100%;
      background: #333;
      transform: translateX(-50%);
    }
  }
  
  @media (min-width: 300px) and (max-width: 850px) {
    .timeline-event {
      top: 1%;
      left: 2.7rem;
    }

    .timeline-item:before {
      content: '';
      position: absolute;
      top: 10%;
      left: 3rem;
      width: 2px;
      height: 100%;
      background: #333;
      transform: translateX(-50%);
    }

    .timeline-item-date {
      padding-left: 4rem;
      font-size: 12px;
    }
    
    .timeline-item-title {
      padding-left: 4rem;
      font-size: 14px;
    }
    
    .timeline-item-description {
      padding-left: 4rem;
      font-size: 12px;
      width: 80%;
    }
  }
  
  @media (max-width: 300px) {
    .timeline-event {
      top: 1%;
      left: 2.7rem;
    }
  }
  
  .timeline-item-content {
    position: relative;
  }
  
  .timeline-item-date {
    display: flex;
    text-align: left;
    color: #777;
    margin-bottom: 5px;
  }
  
  .timeline-item-title {
    display: flex;
    text-align: left;
    margin-bottom: 10px;
  }
  
  .timeline-item-description {
    display: flex;
    text-align: left;
    color: #555;
    width: 80%;
  }